.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin: 200px auto 250px auto;
    max-width: 400px;
    width: 400px;
    min-width: 200px;
}

.inputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 4px;
}

.inputContainer:not(:first-child)
{
    margin-top: 10px;
}

.passwordInput {
    border: none;
    outline: none;
}

.icon {
    margin: 0 4px 0 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.8;
}

.icon:hover {
    transition: 100ms;
    opacity: 1;
}

.passwordForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 180px;
}

.error {
    text-align: left;
    margin-right: auto;
    font-size: 0.8em;
    color: rgb(243, 33, 33);
    margin-top: 4px;
}

.submitButton {
    margin: 10px;
    width: 100%;
    height: 28px;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #444;
    color: #eee;
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.submitButton:hover {
    transition: 100ms;
    background-color: #333;
    color: #fff;
}

.successContainer {
    margin-bottom: 20px;
}
