.container {
    width: 100%;
    background: white;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 10px;
}

.container > header {
    display: flex;
    justify-content: center;
}

.contentContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    pointer-events: none;
}
