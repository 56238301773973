.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin: 200px auto 250px auto;
    max-width: 400px;
    width: 400px;
    min-width: 200px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content > h1 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.5em;
}
