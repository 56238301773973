.container {
    padding: 20px 20px 45px 20px;
    text-align: center;
    width: 100%;
}

.titleContainer {
    margin-bottom: 12px;
}

.title {
    font-size: 1.1em;
    line-height: 1.3em;
}

.subscriptionsContainer {
    list-style-type: none;
    padding: 0;
}

.subscriptionsLoading {
    text-align: left;
    display: flex;
    margin-left: 12px;
}

.success {
    text-align: left;
    margin-left: 7px;
    margin-top: 10px;
    color: rgb(26, 168, 26);
}

.error {
    text-align: left;
    margin-left: 7px;
    margin-top: 10px;
    color: rgb(231, 19, 19);
}

.actionContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
}

.unsubscribe {
    padding: 4px 10px;
    font-size: 1.1em;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #444;
    cursor: pointer;
    margin-top: 12px;
}

.unsubscribe:hover {
    background-color: #ddd;
    border: 1px solid #111;
}

.save {
    padding: 4px 10px;
    font-size: 1.1em;
    width: 100%;
    border: 1px solid #666;
    border-radius: 3px;
    background-color: #111;
    color: #eee;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.save:hover {
    transition: 100ms;
    background-color: #000;
    color: #fff;
}
