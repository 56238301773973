.container {
    display: flex;
}

.labelCheckbox {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    padding: 6px 8px;
    border-radius: 6px;
    overflow: hidden;
    transition: all .2s ease;
    display: flex;
    align-items: center;
}

.labelCheckbox span:not(:last-child) {
    margin-right: 6px;
}

.labelCheckbox:hover {
    background: rgba(40, 40, 40, .06);
}

.labelCheckbox span {
    float: left;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
}

.labelCheckbox span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    transform: scale(1);
    border: 1px solid #CCCFDB;
    transition: all .2s ease;
    box-shadow: 0 1px 1px rgba(#00104B,.05);
}

.labelCheckbox span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
}

.labelCheckbox:hover span:first-child {
    border-color: #07f;
}

.inputCheckbox {
    position: absolute;
    visibility: hidden;
}

.inputCheckbox:checked + .labelCheckbox span:first-child {
    background: #07f;
    border-color: #07f;
    animation: wave 0.4s ease;
}

.inputCheckbox:checked + .labelCheckbox span:first-child svg {
    stroke-dashoffset: 0;
}

.inlineSvg {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
}

@-moz-keyframes wave {
    50% {
      transform: scale(0.9);
    }
}
@-webkit-keyframes wave {
    50% {
      transform: scale(0.9);
    }
}
@-o-keyframes wave {
    50% {
      transform: scale(0.9);
    }
}
@keyframes wave {
    50% {
      transform: scale(0.9);
    }
}
